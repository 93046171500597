export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Perfil de público",
				root: true,
				icon: "fas fa-chart-bar",
				bullet: "dot",
				page: "perfil-publico"
			},
			{
				title: "Campanhas de vídeo",
				root: true,
				icon: "fas fa-play-circle",
				bullet: "dot",
				page: "campanha-video"
			},
			{
				title: "Inventário de campanha",
				root: true,
				icon: "fas fa-box",
				bullet: "dot",
				page: "inventario-campanha"
			},
			{
				title: "Perfil",
				root: true,
				icon: "fas fa-user",
				bullet: "dot",
				page: "perfil"
			},
			// {
			// 	title: "Anunciante",
			// 	root: true,
			// 	icon: "fas fa-bullhorn",
			// 	bullet: "dot",
			// 	page: "anunciante"
			// },
			// {
			// 	title: "Usuários",
			// 	root: true,
			// 	icon: "fas fa-users",
			// 	bullet: "dot",
			// 	page: "users"
			// },
		]
	}
};
