import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const PerfilPage = lazy(() =>
	import("./perfil/PerfilPage")
);

const PerfilPublico = lazy(() =>
	import("./perfil-publico/PerfilPublicoPage")
);

const InventarioCampanha = lazy(() => 
	import("./inventario-campanha/InventarioCampanhaPage")
);

const AnunciantesPage = lazy(() =>
	import("./anunciante/AnunciantePage")
);

const CampanhaVideoPage = lazy(() =>
	import("./campanha-video/CampanhaVideoPage")
);

const ReportPage = lazy(() =>
	import("./report/ReportPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/campanha-video" component={CampanhaVideoPage} />
				<Route path="/perfil-publico" component={PerfilPublico} />
				<Route path="/perfil" component={PerfilPage} />
				<Route path="/anunciante" component={AnunciantesPage} />
				<Route path="/inventario-campanha" component={InventarioCampanha} />
				<Route path="/report" component={ReportPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense >
	);
}
